import React from 'react';
import ImageGallery from 'react-image-gallery';
import _ from 'lodash';

export default function Carousel(props) {
    return (
        <React.Fragment>
            <ImageGallery 
                items={props.img.map(i => ({ original: i, thumbnail: i }))} 
                {... _.omit(props, ['img'])}
            />
        </React.Fragment>
    )
}