import React, { useState } from 'react';
import { withRouter } from 'react-router-dom'
import { Row, Col, Alert } from 'react-bootstrap';
import { CONTACT_FORM } from '../constants/paths';
import { isEmptyOrNull, validate, preProcessText } from './utils';
import FormMaker from './formMaker';

export default withRouter(function MailForm() {
    const [state, setState] = useState({
        mailSent: undefined,
        emailContent: {
            name: undefined,
            mail: undefined,
            subject: undefined,
            tel: undefined,
            message: undefined,
        },
        errors: [],
        mailStatus: 'form',
        inputs: [
            ['Name', 'name', 'text'],
            ['Email', 'mail', 'email'],
            ['Subject', 'subject', 'text'],
            ['Message', 'message', 'textarea'],
            ['Phone', 'tel', 'hidden'],
        ]
    });
   
    function handleInput(type, val) {
        const { emailContent } = state;
        emailContent[type] = val;
        setState({...state, emailContent });
    }

    function submitForm() {
        const err = validate(state.emailContent);
        setState({...state, errors: err});
        if (err.length === 0) {
            setState({...state, mailStatus: 'processing'});
            sendEmail();
        }
    }

    function sendEmail() {
        const { emailContent } = state;

        if (emailContent.tel !== undefined) {
            setState({...state, mailStatus: 'success'});
            return;
        }

        const formData = new FormData();
        const keys = Object.keys(emailContent);
        keys.forEach(k => { formData.append(k, isEmptyOrNull(emailContent[k]) ? '' : preProcessText(emailContent[k])) });

        fetch(CONTACT_FORM, {
            method: 'POST',
            body: formData
        }).then((response) => response).then((json) => { setState({...state, mailStatus: 'success'}) }).catch((response) => { setState({...state, mailStatus: 'error'})});
    }

    function displayMailMessage() {
        const { mailStatus } = state;
        let title;
        let message;

        if (mailStatus === 'processing') {
            title = 'Sending';
            message = 'Processing your request. Please do not close your browser window.';
        } else if (mailStatus === 'success') {
            title = 'Message Sent!';
            message = 'Thanks for sending me a message! I will read and reply to it as soon as I can!';
        } else {
            title = 'Error';
            message = 'There was an error processing your request. Please reload the page and try again.';
        }
        
        return (
            <>
              <center><h1>{title}</h1></center>
              <center>{message}</center>
            </>
        );
    }

    const missingInfoAlert = (
        <>
            <Alert variant="danger">
                <Alert.Heading>Missing Form Values</Alert.Heading>
                <p>There were some values missing from that message! All fields are required, so please fill in any missing data and resubmit your message.</p>
            </Alert>
        </>
    );

    const mailForm = (
        <React.Fragment>
            <Row>
                <Col xl={3} />
                <Col xl={6}>
                    {state.errors.length > 0 ? missingInfoAlert : null}
                </Col>
            </Row>
            <FormMaker 
                inputs={state.inputs}
                errors={state.errors}
                onChange={(type, val) => { handleInput(type, val)}}
            />
            <Row>
                <Col xl={3} />
                <Col xl={6}>
                    <center><button type="submit" name="sendMsg" className="field_submit" onClick={() => { submitForm(); }}>Send Message</button></center>
                </Col>
            </Row>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            {state.mailStatus === 'form' ? mailForm : displayMailMessage()}
        </React.Fragment>
    );
});