import React from 'react';
import { withRouter } from 'react-router-dom'
import * as paths from '../constants/paths';

export default withRouter(function Footer() {
    const today = new Date();
    const { pathname: path } = window.location;
    
    let cname = "footer_fixed";

    if (path === paths.DESIGN || path === paths.DEVELOPMENT) {
        cname = "footer_relative";
    }

    return (
        <React.Fragment>
            <div className={cname}>
                <center>
                    Copyright&#169; 2016-{today.getUTCFullYear()} Matthew Paulette. All Rights Reserved
                </center>
            </div>
        </React.Fragment>
    )
})