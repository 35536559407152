import React from "react";

export function isEmptyOrNull(value) {
    if (value === null || value === undefined || value === "" || value === {} || (Array.isArray(value) && value.length === 0)) {
        return true;
    }
    return false;
}

export function notEmptyOrNull(value) {
    if (value !== null && value !== undefined && value !== '') {
        return true;
    }
    return false;
}

export function validate(obj) {
    const errors = [];
    const keys = Object.keys(obj);
    keys.forEach(k => {
        if (k !== 'tel' && isEmptyOrNull(obj[k])) {
            errors.push(k);
        }
    });
    return errors;
}

export function preProcessText(input) {
    const stripped = input.replace(/[`~#$%^&*()_|+\-=;:"<>\{\}\[\]\\\/]/gi, '');
    return stripped.trim();
}

export function renderLineBreaks(str) {
    const parser = new DOMParser();
    const htmlString = () => parser.parseFromString(str, "text/html").body.childNodes;
    const nodes = htmlString('<br />');
    const keys = Object.keys(nodes);

    const narr = [];
    keys.forEach(k => narr.push(nodes[k]));

    return (
        <React.Fragment>
            {narr.map(m => (notEmptyOrNull(m.nodeValue) ? m.nodeValue : <br />))}
        </React.Fragment>
    )
}
