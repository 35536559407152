import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function TextPanel(props) {
    return (
        <React.Fragment>
            <br /><br />
            <Row>
                <Col lg={12}>
                    <div className="media_panel_header">{props.title}</div><br />
                    {props.text}
                </Col>
            </Row>
            <br /><br />
            <div className="draw_line"></div>
        </React.Fragment>
    )
}