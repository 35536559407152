import React from 'react';
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import * as page from '../constants/pageNames';
import * as paths from '../constants/paths';

export default withRouter(function Title() {
    const { pathname: path } = window.location;

    function getTitle(val) {
        const keys = Object.keys(paths);
        const allPaths = Object.values(paths);
        const curPath = allPaths.findIndex(i => i === val);
        const pageName = curPath === -1 ? page.HOME : page[keys[curPath]];
        return pageName;
    }

    return (
        <React.Fragment>
            <Helmet><title>{getTitle(path)} | MP</title></Helmet>
        </React.Fragment>
    )
})