import React, { useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom'
import * as page from '../constants/pageNames';
import * as paths from '../constants/paths';

export default withRouter(function Navbar() {
    const [visible, setVisible] = useState(false);
    const locations = ['HOME', 'DEVELOPMENT', 'DESIGN', 'BLOG', 'CONTACT'];
    const { pathname: path } = window.location;

    function nav(loc) {
        return <li className={visible ? "nav_link_show" : "nav_link_hide"}><NavLink to={paths[loc]} onClick={() => { setVisible(!visible) }} isActive={() => path === paths[loc]}>{page[loc]}</NavLink></li>;
    }

    return (
        <>
            <ul>{locations.map(m => nav(m))}</ul>
            <button className="menu_toggle show_small" onClick={() => { setVisible(!visible) }}>{visible ? <i class="fas fa-times fa-2x" /> : <i class="fas fa-bars fa-2x" />}</button>
        </>
    )
})