import React from 'react';
import { isEmptyOrNull } from './utils';

export default function PageDescription({ text, title, showLine = true }) {
    const ln = showLine ? <div className="draw_line" /> : null;

    if (isEmptyOrNull(text)) {
        return (<><div className="page_header">{title}</div><br />{ln}</>);
    }

    return (
        <>
            <div className="page_header">{title}</div>
            <p>{text}</p><br />
            {ln}
        </>
    )
}