import React from 'react';
import { withRouter } from 'react-router-dom'
import MailForm from './components/mailForm';
import PageDescription from './components/pageDescription';

export default withRouter(function Contact() {
    return (
        <>
            <div className="page_body gold top_buffer">
                <PageDescription
                    title="Contact"
                    text="If you'd like to give any feedback or ask questions about anything on the site, use the contact form below to send me a message!"
                />
                <br />
                <MailForm />
            </div>
            <br /><br />
        </>
    )
})