import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isEmptyOrNull } from '../utils';

export default function FormTextarea({ label, error, name, len, parentCallback }) {
    const [val, setVal] = useState(undefined);
    return (
        <Row>
            <Col xl={3} />
            <Col xl={6}>
                <div className="field_heading">{label}</div>
                <textarea
                    className={`field_input ${error ? 'error' : null}`}
                    name={name}
                    placeholder={label}
                    onChange={(v) => { const result = !isEmptyOrNull(v) ? v.target.value : null; setVal(result); parentCallback(result); }}
                    value={val}
                    maxLength={len}
                />
            </Col>
        </Row>
    );
}