import React from 'react';
import { withRouter } from 'react-router-dom'

export default withRouter(function Missing() {
    return (
        <React.Fragment>
            <div className="name_main">404</div>
            <br />
            <center>
            <p class="gold">The page you're looking for cant be found.</p>
            </center>
        </React.Fragment>
    )
});