import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'
import { FETCH_BLOG, BLOG } from './constants/paths';
import Loading from './components/loading';
import { isEmptyOrNull, renderLineBreaks } from './components/utils';
import PageDescription from './components/pageDescription';
import BlogDate from './components/blogDate';

export default withRouter(function BlogEntry({ id }) {
    const [isLoading, setLoading] = useState(true);
    const [blogEntry, setBlogEntry] = useState(0);
    const [err, setErr] = useState(0);

    useEffect(() => {
        const formData = new FormData();
        formData.append('source', 'blog');
        formData.append('id', id);

        fetch(FETCH_BLOG, { method: 'POST', body: formData })
            .then(response => response.json())
            .then(data => {
                console.log('blog =', data[0]);
                if (data[0] === undefined) {
                    setErr('This blog entry cannot be found. Please try again, if the problem persist use the contact form on this site to request assistance.');
                } else {
                    setBlogEntry(data[0]);
                }
                setLoading(false);
            })
            .catch(() => {
                setErr('There was an error getting the blog entries from the server. Please refresh the page and try again. If the problem persists, use the contact form and send a message to request tech support.');
                setLoading(false);
            });
    }, []);

    if (isLoading) {
        return <Loading show={true} />
    }

    const content = (
        <>
            {renderLineBreaks(blogEntry.entry)}
            <br /><br />
            <BlogDate entry={blogEntry} />
        </>
    )
  
    return (
        <>
            <div id="div1" className="page_body gold top_buffer">
                <PageDescription title={blogEntry.title} />
                <br />
                {err === 0 ? content : err}
                <br /><br />
            </div>
        </>
    )
})