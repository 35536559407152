import React from 'react';
import { Table } from 'react-bootstrap';
import { isEmptyOrNull } from './utils';

export default function EasyTable({ data, cols }) {
    if (isEmptyOrNull(data) || isEmptyOrNull(cols)) {
        return 'No Data';
    }

    return (
        <Table bordered className="easy_table">
            <thead>
                {<tr>{cols.map(m => <th className="easy_table_header">{m.header}</th>)}</tr>}
            </thead>
            <tbody>
                {data.map(m => (<tr className="easy_table_row">{cols.map(h => <td>{m[h.accessor]}</td>)}</tr>))} 
            </tbody>
        </Table>
    );
}