import React from 'react';
import { withRouter } from 'react-router-dom'

export default withRouter(function Dashboard() {
  return (
    <React.Fragment>
      <div className="name_main animate__animated animate__fadeIn">
        Matthew Paulette
        <br />
        <div className="draw_line_gold" />
        <div className="credentials">
          Developer | Designer
        </div>
      </div>
      <br /><br />
    </React.Fragment>
  );
});