import React from 'react';
import { withRouter } from 'react-router-dom'
import Carousel from './components/carousel';
import List from './components/list';
import MediaPanel from './components/mediaPanel';
import PageDescription from './components/pageDescription';
import { char1, char2, wharf } from './img/index';

export default withRouter(function Design() {
    return (
        <React.Fragment>
            <div className="page_body gold top_buffer">
                <PageDescription
                    title="Design"
                    text={(
                        <React.Fragment>
                            <p>
                                I have been creating digital art in my free time since 2015.
                                As well as providing a creative outlet, it allows me to create assets for the various projects I am working on.
                                Below are some samples of the different styles I enjoy working with.
                                <br /><br />
                                In addition to digital art, I also enjoy designing websites such as this one.
                            </p>
                        </React.Fragment>
                    )}
                />
                <MediaPanel
                    title="Vector Character Design"
                    left={(
                        <React.Fragment>
                            <p>
                                Vector drawing applications allow artists to create imagery that is easily scaled.
                                In the project I am building, character art is drawn with vectors and will be used in menus and, (hopefuly) cutscenes.
                                In addition, vectors allow the artwork to be scaled up to a large format and used for promotional materials, if needed.
                                <br /><br />
                                Shown here are two characters from an upcoming project.
                                </p>
                            <br />
                            <List
                                title="Equipment / Software"
                                rows={[
                                    'iPad Pro / Apple Pencil',
                                    'Adobe Illustrator Draw',
                                    'GIMP'
                                ]}
                            />
                        </React.Fragment>
                    )}
                    right={(
                        <React.Fragment>
                            <Carousel
                                img={[char1, char2]}
                                useBrowserFullscreen={false}
                                shownBullets={false}
                                showPlayButton={false}
                            />
                        </React.Fragment>
                    )}
                />
                <MediaPanel
                    title="Character Sprites and Environments"
                    left={(
                        <React.Fragment>
                            <p>
                                Sprites, sometimes referred to as pixel artwork, serve as the visuals for both games made pre-3D, or games that seek a "retro" style.
                                In the project I'm working on, all backgrounds, objects, and characters are sprite based.
                                <br /><br />
                                Shown here is a battle on the wharf map in a Pre-Alpha build of the game. For the purposes of illustration, any HUD (Heads-up Display) elements have been removed.
                            </p>
                            <br />
                            <List
                                title="Equipment / Software"
                                rows={[
                                    'iPad Pro / Apple Pencil',
                                    'Aseprite',
                                    'Luna Display / Astropad',
                                    'GIMP'
                                ]}
                            />
                        </React.Fragment>
                    )}
                    right={(
                        <React.Fragment>
                            <Carousel
                                img={[wharf]}
                                useBrowserFullscreen={false}
                                shownBullets={false}
                                showPlayButton={false}
                            />
                        </React.Fragment>
                    )}
                />
            </div>
        </React.Fragment>
    )
});