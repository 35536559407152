import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import './App.css';

// Components
import Title from './frontend/components/title';
import Navbar from './frontend/components/navbar';
import Footer from './frontend/components/footer';

// Pages
import Dashboard from './frontend/dashboard';
import Development from './frontend/development';
import Design from './frontend/design';
import Blog from './frontend/blog';
import BlogEntry from './frontend/blogEntry';
import Contact from './frontend/contact';
import Missing from './frontend/missing';

// Constants
import * as paths from './frontend/constants/paths';

class App extends Component {
	constructor() {
    super();
  }
  render() {
    return (
      <Router history={useHistory}>
        <React.Fragment>
          <Title />
          <Navbar />
          <Switch>
            <Route exact path={paths.HOME} render={() => <Dashboard />} />
            <Route exact path={paths.BLOG} render={() => <Blog />} />
            <Route exact path={`${paths.BLOG}/:id`} render={({ match }) => <BlogEntry id={match.params.id} />} />
            <Route exact path={paths.DESIGN} render={() => <Design />} />
            <Route exact path={paths.DEVELOPMENT} render={() => <Development />} />
            <Route exact path={paths.CONTACT} render={() => <Contact />} />
            <Route exact path={paths.MISSING} render={() => <Missing />} />
            <Redirect to={paths.MISSING} />
          </Switch>
          <Footer />
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
