import React from 'react';

export default function Loading({ show }) {
    if (show) {
        return (
            <>
                <center>
                    <div className="loading_container">
                        <div className="loading_icon" />
                        <div className="loading_text">Loading...</div>
                    </div>
                </center>
            </>
        )
    }
    return null;
}