import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { isEmptyOrNull, notEmptyOrNull } from './components/utils';
import { FETCH_ALL_BLOG, BLOG } from './constants/paths';
import PageDescription from './components/pageDescription';
import EasyTable from './components/easyTable';
import Loading from './components/loading';

export default withRouter(function Blog(props) {
  const [isLoading, setLoading] = useState(true);
  const [content, setContent] = useState([]);
  const [err, setErr] = useState(0);

  useEffect(() => {
    const formData = new FormData();
    formData.append('source', 'blog');

    fetch(FETCH_ALL_BLOG, { method: 'POST', body: formData })
    .then(response => response.json())
    .then(data => {
      for (let i = 0; i < data.length; i++) {
        data[i].view = <button className="blog_table_button" onClick={() => props.history.push(`${BLOG}/${data[i].id}`)}><i class="fa fa-book" aria-hidden="false" /></button>
      }
      setContent(data);
      setLoading(false);
    }).catch(() => {
      setErr('There was an error getting the blog entries from the server. Please refresh the page and try again. If the problem persists, use the contact form and send a message to request tech support.');
      setLoading(false);
    });
  }, []);

  const tbl = (
    <EasyTable
      data={notEmptyOrNull(content) ? content : null}
      cols={[
        { header: 'Title', accessor: 'title' },
        { header: 'Date', accessor: 'cdate' },
        { header: 'View', accessor: 'view' }
      ]}
    />
  );

  if (isLoading) {
    return <Loading show={true} />
  }

  return (
    <>
      <div className="page_body gold top_buffer">
        <PageDescription
          title="Blog"
          showLine={false}
        />
        {err === 0 ? tbl : err}
      </div>
    </>
  )
})