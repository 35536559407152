import React from 'react';
import { notEmptyOrNull } from './utils';

export default function BlogDate({ entry }) {
    const { created_at, updated_at } = entry;
    return (
    <>
        <div className="blog_date">
            Date: {new Date(created_at).toLocaleDateString()} {notEmptyOrNull(updated_at) ? `(Updated on: ${new Date(updated_at).toLocaleDateString()})` : null}
        </div>
    </>
    )
}