import React from 'react';
import { FormHidden, FormInput, FormTextarea } from './form/index';
import { isEmptyOrNull } from './utils';

export default function FormMaker({inputs, errors, onChange}) {
    let result = [];
    for (let k = 0; k < inputs.length; k++) {
        result.push(inputMapper(inputs[k], errors));
    }  

    function inputMapper(i) {
        switch (i[2]) {
            case 'textarea':
                return (
                    <FormTextarea
                        name={i[1]}
                        label={i[0]}
                        error={errors.includes(i[1])}
                        len={2000}
                        parentCallback={(v) => { onChange(i[1], v); }}
                    />
                )
            case 'hidden':
                return (
                    <FormHidden
                        name={i[0]}
                        label={i[0]}
                        type={i[1]}
                        error={errors.includes(i[1])}
                        len={100}
                        parentCallback={(v) => { onChange(i[1], v); }}
                    />
                );
            default:
                return (
                    <FormInput
                        name={i[1]}
                        label={i[0]}
                        type={i[2]}
                        error={errors.includes(i[1])}
                        len={100}
                        parentCallback={(v) => { onChange(i[1], v); }}
                    />
                )
        }
    }

    return result;
}