import React from 'react';
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap';
import List from './components/list';
import TextPanel from './components/textPanel';
import PageDescription from './components/pageDescription';

export default withRouter(function Development() {
    return (
        <React.Fragment>
            <div className="page_body gold top_buffer">
                <PageDescription 
                    title="Development"
                    text={(
                        <React.Fragment>
                            <p>
                                I have experience developing software in several languages and environments. In addition, I have a Master's of Science in Computer Science.
                                I am passionate about creating clean, robust software that prioritizes functionality and user-friendliness.
                                <br /><br />
                                This website was built using React, JavaScript, HTML, CSS and PHP. There is also some extra flair added in through Font-Awesome and Google web fonts, among others.
                            </p>
                        </React.Fragment>
                    )}
                />
                <TextPanel
                    title="Web Applications"
                    text={(
                        <React.Fragment>
                            <p>
                                The web has evolved. As developers, we now have access to a powerful array of tools and frameworks that both reduce the
                                complexity and development time, while also greatly improving functionality.
                                </p>
                            <br />
                            <Row>
                                <Col lg={6}>
                                    <List
                                        title="Languages / Frameworks"
                                        rows={[
                                            'React',
                                            'Node.js',
                                            'JavaScript',
                                            'TypeScript',
                                            'SQL',
                                            'Java / JSP',
                                            'HTML',
                                            'CSS / Bootstrap',
                                            'PHP'
                                        ]}
                                    />
                                    <br />
                                </Col>
                                <Col lg={6}>
                                    <Row>
                                        <Col xs={4}>
                                            <center><i class="fab fa-react fa_resizer"/></center>
                                        </Col>
                                        <Col xs={4}>
                                            <center><i class="fab fa-node-js fa_resizer" /></center>
                                        </Col>
                                        <Col xs={4}>
                                            <center><i class="fab fa-js-square fa_resizer" /></center>
                                        </Col>
                                    </Row>
                                    <br /><br />
                                    <Row>
                                        <Col xs={4}>
                                            <center><i class="fab fa-java fa_resizer" /></center>
                                        </Col>
                                        <Col xs={4}>
                                            <center><i class="fab fa-html5 fa_resizer" /></center>
                                        </Col>
                                        <Col xs={4}>
                                            <center><i class="fab fa-php fa_resizer" /></center>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <br />
                                    <div className="pull_right">
                                        <small>The images and logos used above are trademarks of their respective owners.</small>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                />
                <TextPanel
                    title="Video Games"
                    text={(
                        <React.Fragment>
                            <p>
                                In addition to my professional work in web development, I am currently building a game in my spare time. The game will be a turn-based strategy game, reminiscent of classic titles like 'Final Fantasy: Tactics'.
                                Game development requires a unique skillset that is (not necessarily) required in other forms of development. These may include graphing algorithms, artificial intelligence, and resource management to name a few.
                                Check this site often, as I will continue to post updates as the project progresses. If you'd like additional information, use the contact form on this website.
                            </p>
                            <br />
                            <List
                                title="Languages / Frameworks"
                                rows={[
                                    'JavaScript',
                                    'Pixi.js',
                                    'RPG Maker MV',
                                    'Graphing Algorithms / Pathfinding',
                                    'Artificial Intelligence',
                                ]}
                            />
                        </React.Fragment>
                    )}
                />
                <TextPanel
                    title="Machine Learning and Data Science"
                    text={(
                        <React.Fragment>
                            <p>
                                My main graduate research was in the field of Machine Learning and Data Science. In today's information age, the most valuable resource is data; having methods for obtaining and processing such
                                vast amounts of data in a meaningful manner are essential for moving the industry forward.
                                <br /><br />
                                Some projects I have worked on include Twitter API data mining, building an Artificial Neural Network to predict the result of sport games, determining soil status from sample data, among others.
                            </p>
                            <br />
                            <List
                                title="Languages / Frameworks"
                                rows={[
                                    'R / R Studio',
                                    'Python',
                                    'ANNs (Artificial Neural Networks)',
                                    'Decision Trees',
                                    'Naive Bayes',
                                    'SciKit',
                                    'Pandas',
                                    'NumPy',
                                ]}
                            />
                        </React.Fragment>
                    )}
                />
            </div>
        </React.Fragment>
    )
});