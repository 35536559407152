import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { Media, Row, Col } from 'react-bootstrap';

export default withRouter(function MediaPanel(props) {
    const [windowWidth, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => { setWidth(window.innerWidth); }, true);
        return () => {
            window.removeEventListener('resize', {});
        }
    }, []);

    const { title, left, right, flip } = props;
    let media = (<><center>{right}</center><br />{left}</>);
    
    if (windowWidth > 1000) {
        media = flip ? (
            <>
                <Media>
                    {left}
                    <Media.Body>
                        {right}
                    </Media.Body>
                </Media>
            </>
        ) : (
            <>
                <Media>
                    <Media.Body>
                        {left}
                    </Media.Body>
                    {right}
                </Media>
            </>
        );
    }

    return (
        <>
            <br /><br />
            <Row>
                <Col lg={12}>
                    <div className="media_panel_header">{title}</div><br />
                    {media}
                </Col>
            </Row>
            <br /><br />
            <div className="draw_line"></div>
        </>
    )
})